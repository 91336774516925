import { RouteRecordRaw } from 'vue-router';
import { ROUTE_KEY_TYPE } from '@/shared/utils/constants/common';
import { ROUTE_NAMES } from '@/shared/utils/constants/router';

export default [
  {
    path: '/statements',
    children: [
      {
        path: '',
        name: ROUTE_NAMES.statements.list,
        meta: {
          keyName: ROUTE_KEY_TYPE.WORK_WITH_DOCUMENTS
        },
        component: () =>
          import(
            '@/sections/statements/pages/StatementsPage.vue'
          )
      }
    ]
  }
] as RouteRecordRaw[];
