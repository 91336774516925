import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useCommonStore = defineStore(
  'common',
  () => {
    const appVersion = ref('v1');

    const showStubModal = ref(false);
    const isShowHeaderStickyAlert = ref(false);
    const isInited = ref(false);
    const showUzumMarketBannerLast = ref(true);
    const showCounterpartyOnce = ref(true);

    return {
      showStubModal,
      isShowHeaderStickyAlert,
      isInited,
      appVersion,
      showUzumMarketBannerLast,
      showCounterpartyOnce
    };
  },
  {
    persist: {
      key: 'common',
      storage: window.localStorage,
      paths: [
        'appVersion',
        'showUzumMarketBannerLast',
        'showCounterpartyOnce'
      ]
    }
  }
);
