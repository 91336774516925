import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ROUTE_NAMES } from '@/shared/utils/constants/router';
import { depositsRoutes } from '@/app/router/deposits';
import { depositsRoutes as depositsRedesignRoutes } from '@/app/router/deposits-redesign';

export default function useDepositsTest() {
  const router = useRouter();
  const route = useRoute();

  const isReplaced = ref<boolean>(false);

  const replaceDepositRoutes = (businessCode: string) => {
    if (isReplaced.value) return;

    const businessWithRedesignDeposits = [ '05670884' ];
    const needToReplaceDeposits = businessWithRedesignDeposits.includes(businessCode);
    const isDepositsActive = route.path.startsWith('/deposits');

    if (needToReplaceDeposits) {
      depositsRoutes.forEach(route => router.removeRoute(route.name));
      depositsRedesignRoutes.forEach(route => router.addRoute(ROUTE_NAMES.system.defaultLayout, route));

      if (isDepositsActive) {
        const needToRedirect = route.name && !router.hasRoute(route.name);
        const replacePath = needToRedirect ? depositsRedesignRoutes[0].path : route.fullPath;

        router.replace(replacePath);
      }
    }

    isReplaced.value = true;
  };

  return {
    replaceDepositRoutes
  };
}
