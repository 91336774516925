/*
  Маппинг ошибок и отображения их для пользователя в ДБО
  https://wiki.kapitalbank.uz/pages/viewpage.action?pageId=134698973
*/
import { LocationQuery } from 'vue-router';

export const enum OnboardingStatuses {
  // DEFAULT STATUS:
  PRE_LOADING = 999_999_999,
  CONSIDERING_APPLICATION = 0, // - Идет проверка статуса заявки

  // ERROR STATUSES:
  ERROR_NOT_ABLE_TO_OPEN_ACCOUNT_REMOTELY = -1, // - стена
  ERROR_DUPLICATE_APPLICATION = -2, // - Отказ : Дубль заявки
  ERROR_CLIENT_DBO = -3, // Действующий клиент (Обращение действующего клиента KAPITAL BANK)
  ERROR_NOT_ACTIVE = -4, // Бездействующий бизнес - Неактивный
  ERROR_COMPANY_LIQUIDATED = -5, // Ваша компания ликвидирована (Закрылся бизнес)
  ERROR_NO_CONTACT_ESTABLISHED = -6, // Не установлен контакт
  ERROR_SCORING_REFUSAL = -7, // Отказ по скорингу (Открыть счёт дистанционно не получится)

  // NORMAL/SUCCESS STATUSES:
  START = 1, // - start - начало - экран где пользователь - регистрируется ( данный статус вы не видете так как вы заполняете эти поля)
  CONSULTATION = 4, // - Консультация
  COMPANY_FORM_STEP = 5, // - статус заявки где обозначает что нужно показать окно с формой заполнения данных о компании
  EMPLOYEE_FORM_STEP = 6, // - статус заявки где обозначает что нужно показать окно с формой заполнения данных о сотрудниках
  MEET_FORM_STEP = 7, // - статус заявки где обозначает что нужно показать окно с формой заполнения данных о встрече
  FINAL_INFO_STEP = 8, // - статус заявки где обозначает что нужно показать окно с формой c заполнеными данными о том когда будет его всреча и телефоны службы поддержки
  ADDITIONAL_MEET_STEP = 9, // Отображение данных о встрече (Назначили дополнительную встречу)
  FINAL_SUCCESS_STEP = 10, // Встреча прошла успешно
  ACCOUNT_IS_OPEN = 11, // счет открыт
  WAIT_A_BIT = 100, // между 7 и 8 статусами
}

export const enum UserVariations {
  DBO, // Пользователь ДБО = 0
  OnboardingUser, // Пользователь Онбординг = 1
  NewOnboardingUser // Новый пользователь Онбординга = 2
}

type NullableString = string | null;

export type ApplicationId = string;
export interface OnboardingStatus {
  id: ApplicationId;
  state: OnboardingStatuses;
  stateName: string;
  productId: number;
  productName: NullableString;
  companyName: NullableString;
  innOrPinfl?: NullableString;
  isNew: boolean;
}

export interface OnboardingApplicationItem extends OnboardingStatus {}

export type OnboardingApplicationList = OnboardingApplicationItem[];

export interface OnboardingShortInfo {
  id: ApplicationId;
  orgName: string;
  innOrPinfl: string;
  legalAddress: string;
}

export interface OnboardingSendCompanyInfoFormRequest {
  legalAddress: NullableString;
  rateId: number | string;
  estimatedRevenue: NullableString;
  actualAddress: NullableString;
  isNonResident: boolean;
}

export interface OnboardingSendEmplyeeInfoFormRequest {
  role: number | string;
  surname: NullableString;
  name: NullableString;
  patronymic: NullableString;
  phone: NullableString;
}

export const enum MeetingType {
  AT_THE_BANK,
  AT_THE_CLIENT
}

export interface OnboardingSendMeetInfoFormRequest{
  meetingType: MeetingType;
  address: NullableString;
  street: NullableString;
  apartment: NullableString;
  entrance: NullableString;
  floor: NullableString;
  intercom: NullableString;
  meetingCommentary: NullableString;
  branch: NullableString;
  meetingDateTime: NullableString;
}

export interface OnboardingFinalInfoAboutApplication {
  address: NullableString;
  meetingDateTime: NullableString;
  requiredDocuments: string[] | null;
}

export interface OnboardingFinalInfoResponse extends OnboardingFinalInfoAboutApplication, OnboardingStatus {}

export interface LeaveRequestForConsultationRequest {
  phone: string;
  fio: string;
}

export interface ExpectedRevenuePerYear {
  id: number;
  amount: NullableString;
}

export interface ClientContactsForConsultaion {
  phone: string;
  fio: string;
}

export interface OnboardingRole {
  id: number;
  name: string;
  isArchive: boolean;
}

export interface FormattedOnboardingRoleForSelect {
  value: string;
  label: string;
  name: string;
}

export interface OnboardingRate {
  id: number;
  name: NullableString;
  description: NullableString;
  transferIn: NullableString;
  transferOut: NullableString;
  serviceFee: NullableString;
  transferToCardIn: NullableString;
  feeAcceptanceProcessingTransactions: NullableString;
  cashWithdrawal: NullableString;
  swiftTransfers: NullableString;
  acquiring: NullableString;
  accessUZUMBusiness: NullableString;
  reference: NullableString;
}

export interface OnboardingSignInRequestData {
  login: string;
  confirmType: number;
}

export interface OnboardingSignIn {
  userId: number | null;
  userName: NullableString;
  accessToken: NullableString;
  refreshToken: NullableString;
  needConfirm: boolean;
  confirmToken: NullableString;
  confirmPhone: NullableString;
  confirmEmail: NullableString;
}

export interface RefreshToken {
  accessToken: string;
  refreshToken: string;
}

export interface ConfirmToken {
  userId: number;
  confirmToken: NullableString;
  confirmCode: NullableString;
}

export interface ResendSMS {
  userId: number;
  confirmToken: NullableString;
}

export interface ResendSMSResponse {
  confirmToken: NullableString;
}

type OnboardingUserProperties = 'userId' | 'userName';

export type OnboardingUser = Pick<OnboardingSignIn, OnboardingUserProperties>;

export interface OnboardingAuthLocalstorage {
  user: OnboardingUser;
  selectedApplication: OnboardingApplicationItem;
  applicationListData: OnboardingApplicationItem[];
}

export interface OnboardingRegistrationResponse {
  userId: number | null;
  userName: NullableString;
  accessToken: NullableString;
  refreshToken: NullableString;
  needConfirm: boolean;
  confirmToken: NullableString;
  confirmPhone: NullableString;
  confirmEmail: NullableString;
}

export interface OnboardingSignUp extends OnboardingRegistrationResponse {}

export interface OnboardingRegistrationRequestData {
  phone: NullableString;
  innOrPINFL: NullableString;
  orgName?: NullableString;
  fullName?: NullableString;
  source: number;
  product: NullableString;
  utm_Source: NullableString;
  utm_Medium: NullableString;
  utm_Campaign: NullableString;
  utm_Content: NullableString;
  utm_Term: NullableString;
}

export interface Director {
  role: number | string;
  surname: NullableString;
  name: NullableString;
  patronymic: NullableString;
}

interface Branch {
  id: number;
  bankId: NullableString;
  miniBankId: NullableString;
  bankName: NullableString;
  headBankId: NullableString;
  bankAddress: NullableString;
  juridicalBankId: NullableString;
  cityId: number;
  city: NullableString;
}

export interface City {
  id: number;
  name: NullableString;
  branches: Branch[];
}

export interface BankBranchesRequest {
  deliveryType: number;
  cityId?: number;
}

export interface BankMfo {
  id: number;
  name: NullableString;
  address: NullableString;
  addressUz: NullableString;
  branchCode: NullableString;
  tobo: number;
}

export interface BusinessDays {
  curDate: NullableString;
  state: NullableString;
  description: NullableString;
  nextDate: NullableString;
}

export interface TariffDescription {
  title: string;
  key: keyof OnboardingRate;
}

export interface ValidateAuthLink extends LocationQuery {}
