export const ROUTE_NAMES = {
  system: {
    defaultLayout: 'default-layout',
    rootLayout: 'root-layout',
    authGuard: 'auth-guard'
  },
  documents: {
    paymentRequirementsClosed: 'payment-requirements.closed',

    cardIndexesFirstIndex: 'card-indexes-first.index',
    cardIndexesFirstDetails: 'card-indexes-first.details',
    closedCardIndexesFirstDetails: 'closed-card-indexes-first.details',

    cardIndexesSecondIndex: 'card-indexes-second.index',
    cardIndexesSecondDetails: 'card-indexes-second.details',
    closedCardIndexesSecondDetails: 'closed-card-indexes-second.details'
  },
  deposits: {
    main: 'deposits',
    details: 'deposits.details',
    open: 'deposits.open',
    replenishment: 'deposits.replenishment'
  },
  dbo: {
    auth: {
      instruction: 'instruction'
    }
  },
  onboarding: {
    main: 'onboarding',
    auth: {
      leadFormAuth: 'lead-form-auth'
    }
  },
  statements: {
    list: 'statements'
  }
} as const;
