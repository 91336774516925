import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  id: "flag-icons-ru",
  viewBox: "0 0 640 480",
  width: "24",
  height: "24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      "fill-rule": "evenodd",
      "stroke-width": "1pt"
    }, [
      _createElementVNode("path", {
        fill: "#fff",
        d: "M0 0h640v480H0z"
      }),
      _createElementVNode("path", {
        fill: "#0039a6",
        d: "M0 160h640v320H0z"
      }),
      _createElementVNode("path", {
        fill: "#d52b1e",
        d: "M0 320h640v160H0z"
      })
    ], -1)
  ])))
}
export default { render: render }