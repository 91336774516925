import { ROUTE_NAMES } from '@/shared/utils/constants/router';

export const onboardingRoutes = [
  {
    path: '/',
    name: ROUTE_NAMES.onboarding.main,
    meta: {},
    component: () => import('@/pages/onboarding/open-account/OpenAccount.vue')
  },
  {
    path: '/lead-form-auth',
    name: ROUTE_NAMES.onboarding.auth.leadFormAuth,
    meta: {},
    component: () => import('@/pages/onboarding/open-account/OpenAccount.vue')
  }
] as const;
